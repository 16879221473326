(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-columns/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-columns/assets/javascripts/render-component.js');

'use strict';

function _asyncIterator(r) { var n, t, o, e = 2; for ("undefined" != typeof Symbol && (t = Symbol.asyncIterator, o = Symbol.iterator); e--;) { if (t && null != (n = r[t])) return n.call(r); if (o && null != (n = r[o])) return new AsyncFromSyncIterator(n.call(r)); t = "@@asyncIterator", o = "@@iterator"; } throw new TypeError("Object is not async iterable"); }
function AsyncFromSyncIterator(r) { function AsyncFromSyncIteratorContinuation(r) { if (Object(r) !== r) return Promise.reject(new TypeError(r + " is not an object.")); var n = r.done; return Promise.resolve(r.value).then(function (r) { return { value: r, done: n }; }); } return AsyncFromSyncIterator = function (r) { this.s = r, this.n = r.next; }, AsyncFromSyncIterator.prototype = { s: null, n: null, next: function () { return AsyncFromSyncIteratorContinuation(this.n.apply(this.s, arguments)); }, return: function (r) { var n = this.s.return; return void 0 === n ? Promise.resolve({ value: r, done: !0 }) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); }, throw: function (r) { var n = this.s.return; return void 0 === n ? Promise.reject(r) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); } }, new AsyncFromSyncIterator(r); }
const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('banner-groups: banner-columns');
} else {
  logger = svs.core.log.getLogger('banner-groups: banner-columns');
}

async function renderComponent(data, widgetInfo, req, res) {
  let hbsTemplateCompiled;
  let columnsCount;
  if (isServer) {
    const hbs = global.internalInstances.get('hbs');
    hbsTemplateCompiled = hbs.cache['components-banner_groups-banner_columns-banner_columns'];
  } else {
    hbsTemplateCompiled = svs.banner_groups.banner_columns.templates.banner_columns;
  }
  let i = 0;
  const childElements = [];
  let columnWrapper = 'col-xs-12';
  let columns;
  var _iteratorAbruptCompletion = false;
  var _didIteratorError = false;
  var _iteratorError;
  try {
    for (var _iterator = _asyncIterator(data.banners), _step; _iteratorAbruptCompletion = !(_step = await _iterator.next()).done; _iteratorAbruptCompletion = false) {
      const banner = _step.value;
      {
        if (banner.layoutType) {
          let bannerComponent;
          columns = banner.layoutConfig.allowedColumns[0];
          if (isServer) {
            bannerComponent = data.components["banner_layouts/".concat(banner.layoutType)];
          } else {
            bannerComponent = svs.banner_layouts[banner.layoutType.replace(/-/g, '_')];
          }
          if (columns && columns > 1 && data.banners.length <= 1 || columns && columns > 1 && widgetInfo.count === 1) {
            return;
          }
          try {
            const bannerHtml = await bannerComponent.renderComponent(banner, widgetInfo, i + 1, req, res);
            if (columns) {
              columnsCount = 12 / columns;
              columnWrapper = "banner-column-wrapper col-xs-12 col-am-".concat(columnsCount);
            }
            childElements.push({
              html: bannerHtml,
              columnWrapper,
              centerColumns: data.config.centerColumns
            });
          } catch (error) {
            logger.warn("Banner ".concat(banner.id, " skipped due to banner loading error: ").concat(error.toString()), req);
          }
        } else {
          logger.warn("Banner id ".concat(banner.id, " lacks layoutType property"));
        }
        i++;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (_iteratorAbruptCompletion && _iterator.return != null) {
        await _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
  const model = {
    title: data.config.title,
    widgetId: data.config.widgetId,
    subTitle: data.config.subTitle,
    childElements
  };
  return hbsTemplateCompiled(model) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_groups.banner_columns = {
    renderComponent
  };
}

 })(window);