(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-columns/views/banner-columns.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-columns/views/banner-columns.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_columns=_svs.banner_groups.banner_columns||{};
_svs.banner_groups.banner_columns.templates=_svs.banner_groups.banner_columns.templates||{};
svs.banner_groups.banner_columns.templates.banner_columns = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isEven")||(depth0 && lookupProperty(depth0,"isEven"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isEven","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":26}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":136}}})) != null ? stack1 : "")
    + "\n      <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":65}}})) != null ? stack1 : "")
    + "'>\n          <div class='banner-column-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnClasses") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":88}}})) != null ? stack1 : "")
    + "'>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"html") || (depth0 != null ? lookupProperty(depth0,"html") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"html","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n          </div>\n      </div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isEven")||(depth0 && lookupProperty(depth0,"isEven"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isEven","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":25}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":40}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(data && lookupProperty(data,"last")),"&&",(lookupProperty(helpers,"isOdd")||(depth0 && lookupProperty(depth0,"isOdd"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isOdd","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":40}}}),{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":62}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='grid-row column-flex "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"centerColumns") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":70},"end":{"line":3,"column":127}}})) != null ? stack1 : "")
    + "'>";
},"5":function(container,depth0,helpers,partials,data) {
    return "u-flex-justify-content-center";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"columnWrapper") || (depth0 != null ? lookupProperty(depth0,"columnWrapper") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"columnWrapper","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":58}}}) : helper))) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"columnClasses") || (depth0 != null ? lookupProperty(depth0,"columnClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"columnClasses","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":81}}}) : helper))) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"13":function(container,depth0,helpers,partials,data) {
    return " </div> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='banner-group-columns padding-xs-h-1 padding-am-h-1 gutter-am-2'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_columns-banner_columns'] = svs.banner_groups.banner_columns.templates.banner_columns;
})(svs, Handlebars);


 })(window);